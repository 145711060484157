import React, { Component } from 'react'


export default class About extends Component {
    render() {
        return(
            // <!-- About-->
            <section class="page-section" id="about">
                <div class="container">
                    <div class="text-center">
                        <h2 class="section-heading text-uppercase">About</h2>
                        <h3 class="section-subheading text-muted">The Journey Up Until Today</h3>
                    </div>
                    <ul class="timeline">
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/1.png" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>2014 - 2018</h4>
                                    <h4 class="subheading">Introduction to STEM</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                        Stephanie attended The Young Women's Leadership School of Astoria where she was first introduced to coding in her Spanish class that was infused with technology. During her high school journey, she was part of her school's Girls Who Code Club, Riley's Youth Advisory Council, Tech Crew, Digital Dance, and the Robotics Club. She was a student in the Girls Who Code Summer Immersion Program and a teaching assistant in her school's summer camp called TYWLS Tech Explorers. She won the following awards during her time at TYWLS: Recipient of the 2018 National Honorable Mention Winner, Recipient of the 2017 Greater New York City Affiliate Winner, Verizon Innovative App Challenge, Most Innovative Project at the Latism Conference, Finalists in Dream it. Code it. Win it.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/2.jpeg" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>January 2020</h4>
                                    <h4 class="subheading">Internship at Kargo</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                    She worked with a team of four other women to develop a MEAN stack web application to help visualize Kargo's Brand Safety Service of a URL. During her time at Kargo, she was able to not only grow her technical skills with hands-on experience and small lessons from employees, but she was also able to grow her communications skills.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/3.jpeg" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>Summer 2021</h4>
                                    <h4 class="subheading"> Studio Intern at NameGlo</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                        She supported the production team on sales production and fulfillment tasks. She assisted with production and storing equipment as well as labor work for shipping & handling. She conducted desktop research and consulted with vendors to reduce cost and increase sustainability. She learned and used Excel in order to track sales reports to increase profit. Stephanie also composed and reviewed documentation.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>December 2021</h4>
                                    <h4 class="subheading">Graduated From Hunter College</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                        Stephanie graduated from Hunter College with a Bachelor of Arts in Computer Science. She minored in Mathematics and English. She took courses like Computer Theory, Practical Web Development, Introduction to Cyber Risk, Operating Systems, VR/AR/Mixed Reality, Graph Theory.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/5.png" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>June 2021 - February 2022</h4>
                                    <h4 class="subheading"> Cashier at Buffalo Exhange</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                        She meticulously handled POS system transactions including credit, cash, check, returns and refunds to ensure seamless and accurate cash flow. She maintained a clean, organized inventory and curated displays of rotating seasonal clothing and accessories. She anticipated customer needs and provided them with high quality service while upholding brand standards and values to ensure customer satisfaction. Stephanie happily guided customers through the process of finding their ideal styles and actively stayed informed about the latest sales and programs.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/6.jpeg" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>Janaury 2022 - Present</h4>
                                    <h4 class="subheading">M.S at NYIT</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                    Stephanie is currently pursuing her master’s at NYIT'S Computer Science, M.S program where she hopes to grow her knowledge of computer algorithms, computational theory, computer architecture, operating systems, and more.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/7.jpeg" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4>March 2022 - Present</h4>
                                    <h4 class="subheading">Technology Architect Delivery Analyst at Accenture</h4>
                                </div>
                                <div class="timeline-body">
                                    <p class="text-muted">
                                        Stephanie plans to conduct architecture assessments to determine technology solutions. As well as design, build, and deliver solutions from development to execution.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image">
                                <h4>
                                    More
                                    <br />
                                    To
                                    <br />
                                    Come!
                                </h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }
}
    