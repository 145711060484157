import React, { Component } from 'react'


export default class Header extends Component {
    render() {
        return(
            // <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-lg-start">Copyright &copy; bravoheras.com </div>
                    <div class="col text-lg-end">
                    <a class="btn btn-dark btn-social mx-2" href="https://twitter.com/stephanieb_0213"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/stephanie.bravo0213/"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/stephanie-bravo-heras/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://github.com/stephanieb00"><i class="fab fa-github"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="mailto:stephanie.bravo0213@gmail.com"><i class="fa fa-envelope"></i></a>
                    </div>
                </div>
            </div>
        </footer>
        );
    }
}