
import React, { Component } from 'react';
import './App.css';

import NavBar from './components/navbar'
import Header from './components/header'
import Skills from './components/skills'
import Portfolio from './components/portfolio'
import About from './components/about'
import Footer from './components/footer'

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
				<div id="colorlib-main">
          <NavBar></NavBar>
          <Header></Header>
          <Skills></Skills>
          <Portfolio></Portfolio>
          <About></About>
          <Footer></Footer>
        </div>
      	</div>
      </div>
    );
  }
}

export default App;