import React, { Component } from 'react'


export default class Header extends Component {
    render() {
        return(
            <header class="masthead">
                <div class="container">
                    {/* <div class="masthead-subheading">Hey, I'm</div> */}
                    <div class="masthead-heading text-uppercase">Stephanie Bravo-Heras</div>
                    
                    <a class="btn btn-primary btn-xl text-uppercase" href="#skills">Tell Me More</a>
                </div>
            </header>
        );
    }
}