import React, { Component } from 'react'


export default class Skills extends Component {
    render() {
        return(
            <section class="page-section" id="skills">
                <div class="container">
                    <div class="text-center">
                        <h2 class="section-heading text-uppercase">Skills</h2>
                        <h3 class="section-subheading text-muted">Technical Languages & Framewords</h3>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                               <i class="devicon-cplusplus-line-wordmark colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-csharp-line-wordmark colored"></i>
                            </span>
                           
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-arduino-plain-wordmark colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-html5-plain-wordmark colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-css3-plain-wordmark colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-javascript-plain colored"></i>
                            </span>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                               <i class="devicon-react-original colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-unity-original colored"></i>
                            </span>
                           
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-python-plain colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-vscode-plain colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-bash-plain colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-django-plain-wordmark colored"></i>
                            </span>
                        </div>
                    </div>


                    <div class="row text-center">
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                               <i class="devicon-docker-plain colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-figma-plain colored"></i>
                            </span>
                           
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-git-plain colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-github-plain colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-npm-original-wordmark colored"></i>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <span class="fa-stack fa-4x">
                                <i class="devicon-mysql-plain colored"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}